<template>
  <NuxtLink
    v-if="menuItem?.path && !isActiveItem(menuItem)"
    v-show="isLink(menuItem)"
    :to="menuItem?.path"
    class="link-in-menu flex w-full items-center gap-3 px-5 py-4 pr-0 font-semibold text-supporting-black-87"
    :class="{
      [`${menuItem.classes}`]: menuItem.classes,
    }"
    :rel="menuItem.isNoFollow ? 'nofollow' : null"
    @click="closeAndScrollToTop()"
  >
    <StaticPictogram
      v-if="menuItem?.iconMobile || menuItem?.icon"
      :name="menuItem?.iconMobile || menuItem?.icon"
      size="lg"
      :class="
        menuItem?.icon === 'apotek' //Fix for Safari bug that blurs images with shadows
          ? 'shadow-[rgba(17,_15,_14,_0.16)_0px_0px_4px]'
          : ''
      "
    />
    {{ menuItem.name }}
  </NuxtLink>

  <div
    v-if="isActiveItem(menuItem)"
    class="relative mb-1 flex items-center pb-4 pl-5 pt-0 after:absolute after:bottom-0 after:right-0 after:h-[1px] after:w-[calc(100%-1.25rem)] after:bg-black/6 after:content-['']"
  >
    <h2 class="text-2xl font-normal text-supporting-black-87">
      {{ menuItem.name }}
    </h2>
    <NuxtLink
      v-if="menuItem?.path"
      :to="menuItem?.path"
      class="link-in-menu ml-auto pb-2 pl-5 pr-6 pt-3 text-primary-default"
      @click="closeAndScrollToTop()"
    >
      {{ $t('editorial.show_all') }}
    </NuxtLink>
  </div>

  <button
    v-if="isButton(menuItem)"
    class="relative flex w-full items-center gap-4 border-b border-transparent px-5 pr-3 font-semibold text-supporting-black-87"
    :class="buttonStyles(menuItem)"
    @click="setActiveId(+menuItem.entityId)"
  >
    <StaticPictogram
      v-if="menuItem?.iconMobile || menuItem?.icon"
      class="px-[1px] py-[2px]"
      :name="menuItem?.iconMobile || menuItem?.icon"
      size="lg"
    />
    {{ menuItem.name }}
    <img
      class="pictogram ml-auto size-6 -rotate-180 items-center opacity-30"
      name="chevron"
      src="~/assets/pictograms/static/chevron.svg"
      fetchpriority="low"
    />
  </button>
</template>
<script lang="ts" setup>
import { useGlobalMenuStore } from '~/stores/headerMenu';
import { type MenuItem } from '../../../server/types/menu-types';

defineProps({
  menuItem: {
    type: Object as PropType<MenuItem>,
    required: false,
    default: () => ({}),
  },
});

const headerMenu = useGlobalMenuStore();
await headerMenu.getMenu();

const setActiveId = (id: number) => {
  headerMenu.setActiveId(id);
  headerMenu.setLastClickedTopLevelMenuItemId(id);
};

const closeAndScrollToTop = () => {
  headerMenu.setIsNavMenuOpen(false);
  headerMenu.setActiveId(0);
  window.scrollTo(0, 0);
};

const isActiveItem = (menuItem: MenuItem) => menuItem.isActive;
const isLink = (menuItem: MenuItem) =>
  !menuItem?.children?.length && menuItem.isVisible;
const isButton = (menuItem: MenuItem) =>
  menuItem?.children?.length && menuItem.isVisible && !menuItem.isActive;
const isTopLevel = (menuItem: MenuItem) => menuItem?.level === 1;

const buttonStyles = (menuItem: MenuItem) => {
  if (isTopLevel(menuItem)) {
    return `py-5 after:absolute after:bottom-0 after:h-[1px] after:w-[calc(100%-1.25rem)] after:bg-black/6 after:content-[''] ${menuItem.classes}`;
  }
  return `py-4 ${menuItem.classes}`;
};
</script>
